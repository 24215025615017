export const Difficulty = {
  AdvancedEasy: 7,
  AdvancedHard: 9,
  AdvancedMedium: 8,
  BeginnerEasy: 1,
  BeginnerHard: 3,
  BeginnerMedium: 2,
  IntermediateEasy: 4,
  IntermediateHard: 6,
  IntermediateMedium: 5,
} as const;

export type Difficulty = (typeof Difficulty)[keyof typeof Difficulty];

export enum Goal {
  BodyFatReduction = 'BodyFatReduction',
  Endurance = 'Endurance',
  Flexibility = 'Flexibility',
  GeneralFitness = 'GeneralFitness',
  Hypertrophy = 'Hypertrophy',
  Power = 'Power',
  Prehab = 'Prehab',
  Recovery = 'Recovery',
  Rehab = 'Rehab',
  Strength = 'Strength',
}

export interface Orderable {
  position: number;
}

export interface Timestamps {
  createdAt?: string;
  deletedAt?: null | string;
  updatedAt?: string;
}
