import { QueryCache, QueryClient } from '@tanstack/react-query';

export const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache,
});
