const envs = {
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  baseUrl: import.meta.env.VITE_BASE_URL,
  cognito: {
    clientId: import.meta.env.VITE_COGNITO__APP_CLIENT_ID,
    userPoolId: import.meta.env.VITE_COGNITO__USER_POOL_ID,
  },
  mixpanel: {
    token: import.meta.env.VITE_MIXPANEL__TOKEN,
  },
  paddle: {
    token: import.meta.env.VITE_PADDLE__TOKEN,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY__DSN,
  },
  stage: import.meta.env.VITE_STAGE,
};

export default envs;
