class DB {
  has(key: string) {
    return localStorage.getItem(key) !== null;
  }

  read<T>(key: string): null | T {
    const serializedValue = localStorage.getItem(key);
    return serializedValue === null ? null : JSON.parse(serializedValue);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  save<T>(key: string, value: T) {
    return localStorage.setItem(key, JSON.stringify(value));
  }
}

export const StorageService = new DB();

export enum StorageKeys {
  RefreshSession = 'RefreshSession',
  RefreshToken = 'RefreshToken',
  Token = 'Token',
  User = 'User',
}
