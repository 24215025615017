import { FormId } from '../../constants/forms/form';

interface FormProp {
  description: string;
  info: string;
  title: string;
}

const translationForm: Record<FormId, FormProp> = {
  [FormId.ClientCreate]: {
    description: 'Add a new client to your account',
    info: "Please fill in the client's details to add them.",
    title: 'Add a New Client',
  },
  [FormId.ClientEdit]: {
    description: '',
    info: '',
    title: 'Edit Client',
  },
  [FormId.ClientRoutineDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Routine',
  },
  [FormId.ClientRoutineDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Routine',
  },
  [FormId.ClientWorkoutDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Workout',
  },
  [FormId.ClientWorkoutDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Workout',
  },
  [FormId.CommentsCreator]: {
    description: 'Comments',
    info: 'Add or remove comments the clients will see.',
    title: 'Comments',
  },
  [FormId.CycleCreate]: {
    description: '',
    info: '',
    title: 'Create Cycle',
  },
  [FormId.CycleEdit]: {
    description: '',
    info: '',
    title: 'Edit Cycle',
  },
  [FormId.ExerciseCreate]: {
    description: '',
    info: '',
    title: 'Create New Exercise',
  },
  [FormId.ExerciseEdit]: {
    description: '',
    info: '',
    title: 'Edit Exercise',
  },
  [FormId.PasswordChange]: {
    description: 'Change your current password',
    info: 'To change your password, please provide your current and new password.',
    title: 'Change Password',
  },
  [FormId.PasswordForgot]: {
    description: 'Forgot your password?',
    info: 'Please enter your email to reset your password.',
    title: 'Forgot Password',
  },
  [FormId.PasswordNew]: {
    description: 'Enter your new password',
    info: 'Please create a password that is at least 8 characters long and includes one uppercase letter, one lowercase letter, one number, and one special character.',
    title: 'New Password',
  },
  [FormId.PasswordReset]: {
    description: 'Reset your password',
    info: 'Please enter your new password to reset it.',
    title: 'Reset Password',
  },
  [FormId.RoutineCreate]: {
    description: '',
    info: '',
    title: 'Create Routine',
  },
  [FormId.RoutineGenerate]: {
    description: 'This template will be used to create routines for clients.',
    info: 'Choose clients you want the routine to be created for.',
    title: 'Generate routine for client',
  },
  [FormId.SignIn]: {
    description: 'Sign in to your account',
    info: 'Please enter your credentials to sign in.',
    title: 'Sign In',
  },
  [FormId.SignUp]: {
    description: 'Create a new account',
    info: 'To create an account, please fill in the required information.',
    title: 'Create Account',
  },
  [FormId.TemplateRoutineDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Routine Template',
  },
  [FormId.TemplateRoutineDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Routine Template',
  },
  [FormId.TemplateWorkoutDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Workout Template',
  },
  [FormId.TemplateWorkoutDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Workout Template',
  },
};

export default translationForm;
