/**
 * @file We use ids to identify validation errors in combination with yup and joi
 * The translations can be found in {@link file://./../../i18n/en/form-field-validation-error.ts}
 */

/**
 * Ids of form validation errors
 */
export enum FormValidationError {
  Invalid = 'Invalid',
  InvalidEmail = 'InvalidEmail',
  InvalidPassword = 'InvalidPassword',
  InvalidPasswordNoMatch = 'InvalidPasswordNoMatch',
  Required = 'Required',
}
