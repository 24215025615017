import {
  confirmSignUp as cognitoConfirmSignUp,
  signIn as cognitoSignIn,
  signUp as cognitoSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  resendSignUpCode,
} from 'aws-amplify/auth';

import { queryClient } from '../../config/react-query';
import { User, UserType } from '../../features/user/types';
import { clearMixpanelUser, setMixpanelUser } from '../analytics';
import { clearSentryUser, setSentryUser } from '../sentry';
import { StorageKeys, StorageService } from '../storage';
import {
  IConfirmSignUp,
  IResendVerificationCode,
  ISignIn,
  ISignUp,
} from './types';

/**
 * Get the current user from the Cognito session.
 * @returns A promise that resolves to the session user or rejects with an error.
 */
// eslint-disable-next-line complexity
export async function getCurrentCognitoUser() {
  const [sessionUser, session] = await Promise.all([
    fetchUserAttributes(),
    fetchAuthSession(),
  ]);

  if (!sessionUser) {
    clearAuthState();
  }

  const groups = session.tokens?.accessToken.payload?.['cognito:groups'] ?? [];

  const user: User = {
    avatarUrl: null,
    coachId: sessionUser['custom:coach_id'] ?? '',
    email: sessionUser.email ?? '',
    emailVerified: sessionUser.email_verified ?? '',
    firstName: sessionUser.given_name ?? '',
    id: sessionUser.sub ?? '',
    lastName: sessionUser.family_name ?? '',
    username: sessionUser['cognito:username'] ?? '',
    userType: (Array.isArray(groups) ? groups[0] : groups) as UserType,
  };

  StorageService.save(StorageKeys.User, user);
  setSentryUser(user);
  setMixpanelUser(user);

  return user;
}

// /**
//  * Get the Cognito user session for the current user.
//  * @returns A promise that resolves to the Cognito user session or rejects with an error.
//  */
// export function getSession() {
//   return fetchAuthSession();
// }

/**
 * Sign up a user.
 * @param signUpData                - The sign-up data.
 * @param signUpData.email          - The user's email.
 * @param signUpData.firstName      - The user's first name.
 * @param signUpData.lastName       - The user's last name.
 * @param signUpData.password       - The user's password.
 * @param signUpData.username       - The user's username.
 * @param signUpData.clientMetadata - Optional client metadata (e.g., userType).
 * @returns                         A promise that resolves to the sign-up result or rejects with an error.
 */
export function signUp({
  clientMetadata,
  email,
  firstName,
  lastName,
  password,
  username,
}: ISignUp) {
  return cognitoSignUp({
    options: {
      clientMetadata,
      userAttributes: {
        family_name: lastName,
        given_name: firstName,
        preferred_username: username,
      },
    },
    password,
    username: email,
  });
}

/**
 * Confirm a user's sign-up.
 * @param confirmData                The confirmation data.
 * @param confirmData.code           The confirmation code.
 * @param confirmData.email          The user's email.
 * @param confirmData.clientMetadata Optional client metadata (e.g., coachId).
 * @returns                          A promise that resolves when the confirmation is successful or rejects with an error.
 */
export function confirmSignUp({ clientMetadata, code, email }: IConfirmSignUp) {
  return cognitoConfirmSignUp({
    confirmationCode: code,
    options: { clientMetadata },
    username: email,
  });
}

/**
 * Resend a verification code to a user.
 * @param resendData         - The resend data.
 * @param resendData.coachId - The ID of the coach (optional).
 * @param resendData.email   - The user's email.
 * @returns                  A promise that resolves to `true` when the code is successfully resent or rejects with an error.
 */
export function resendVerificationCode({
  coachId,
  email,
}: IResendVerificationCode) {
  const clientMetadata = coachId ? { coachId, email } : undefined;
  return resendSignUpCode({ options: { clientMetadata }, username: email });
}

/**
 * Sign in a user.
 * @param signInData                - The sign-in data.
 * @param signInData.email          - The user's email.
 * @param signInData.password       - The user's password.
 * @param signInData.clientMetadata - Optional client metadata.
 * @returns                         A promise that resolves to the user session or rejects with an error.
 */
export async function signIn({ clientMetadata, email, password }: ISignIn) {
  await cognitoSignIn({
    options: { clientMetadata },
    password,
    username: email,
  });
}

export function clearAuthState() {
  if (window.navigator.onLine === false) {
    return;
  }
  StorageService.remove(StorageKeys.User);
  queryClient.invalidateQueries();
  queryClient.removeQueries();
  clearSentryUser();
  clearMixpanelUser();
}
