import { FormatFunction } from 'i18next';

import { translate } from '..';

export const formatDaysAgo: FormatFunction = (days: string | undefined) => {
  if (days === undefined) {
    return '';
  }

  const daysNumber = Number(days);

  if (daysNumber === 0) {
    return translate('common.dates.days_ago_zero');
  }

  if (daysNumber === 1) {
    return translate('common.dates.days_ago_one');
  }

  return translate('common.dates.days_ago_other', {
    count: daysNumber,
  });
};

export const formatInHours: FormatFunction = (hours: string | undefined) => {
  const hoursNumber = Number(hours);
  if (hoursNumber === 0) {
    return translate('common.dates.hours_in_zero');
  }

  if (hoursNumber === 1) {
    return translate('common.dates.hours_in_one');
  }

  return translate('common.dates.hours_in_other', {
    count: hoursNumber,
  });
};

export const formatLowercase: FormatFunction = (value: string) => {
  return value.toLowerCase();
};

const formatters: Record<string, FormatFunction> = {
  daysAgo: formatDaysAgo,
  inHours: formatInHours,
};

const format: FormatFunction = (value, format, ...args) => {
  if (format === undefined) {
    return value;
  }

  const formatter = formatters[format];

  if (formatter === undefined) {
    return value;
  }

  return formatter(value, format, ...args);
};

export default format;
