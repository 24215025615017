const QueryKey = {
  Client: (id: null | string) => ['Client', id],
  ClientRoutine: (routineId: null | string) => ['ClientRoutine', routineId],
  ClientRoutines: (clientId: null | string) => ['ClientRoutines', clientId],
  Clients: () => ['Clients'],
  ClientWorkout: (workoutId: null | string) => ['ClientWorkout', workoutId],

  Exercise: (id: null | null | string) => ['Exercise', id],
  Exercises: () => ['Exercises'],

  TemplateRoutine: (id: null | string) => ['TemplateRoutine', id],
  TemplateRoutines: () => ['TemplateRoutines'],

  TemplateWorkout: (id: null | string) => ['TemplateWorkout', id],
  TemplateWorkouts: () => ['TemplateWorkouts'],

  User: () => ['User'],
};

export const REACT_QUERY__REFETCH_OPTIONS__ON = {
  refetchOnMount: true,
  refetchOnReconnect: true,
  refetchOnWindowFocus: true,
} as const;

export type QueryRefetchOptions = Partial<
  {
    enabled: boolean;
    refetchInterval: number;
    staleTime: number;
  } & typeof REACT_QUERY__REFETCH_OPTIONS__ON
>;

export { QueryKey };
