/**
 * @file A React hook for tracking the dimensions of the window.
 */
import { useDebouncedState, useWindowEvent } from '@mantine/hooks';

interface Dimensions {
  height: number;
  width: number;
}

/**
 * A React hook for tracking the dimensions of the window.
 * @returns An object containing the current height and width of the window.
 */
const useWindowDimensions = (): Dimensions => {
  const [dimensions, setDimensions] = useDebouncedState(
    {
      height: window.innerHeight,
      width: window.innerWidth,
    },
    50,
  );

  /**
   * Event handler to update the dimensions state when the window is resized.
   */
  useWindowEvent('resize', () => {
    setDimensions({ height: window.innerHeight, width: window.innerWidth });
  });

  return dimensions;
};

export default useWindowDimensions;
