/**
 * @file Contains translations for workouts
 */

import { WorkoutStatus } from '../../features/routine/@client/types';

const status: Record<WorkoutStatus, { label: string }> = {
  [WorkoutStatus.Completed]: {
    label: 'Completed',
  },
  [WorkoutStatus.CompletedWithMissingData]: {
    label: 'Missing Data',
  },
  [WorkoutStatus.InProgress]: {
    label: 'In Progress',
  },
  [WorkoutStatus.None]: {
    label: 'Todo',
  },
  [WorkoutStatus.Skipped]: {
    label: 'Skipped',
  },
};

const translationsWorkout = {
  add: 'Add Workout',
  client_one: 'Client workout',
  client_other: 'Client workouts',
  compare: 'Compare with another workout',
  delete: 'Delete Workout',
  duplicate: 'Duplicate Workout',
  edit: 'Edit Workout',
  info: 'Workout Details',
  last_completed: 'Last workout completed',
  last_completed_zero: 'No workouts completed yet',
  missingData_one: 'One completed workout has incomplete set details!',
  missingData_other: '{{count}} completed workouts have missing set details! ',
  next: 'Next workout',
  ongoing: 'Ongoing workouts',
  ongoing_zero: 'No Ongoing Workouts',
  overview: 'Workouts overview',
  reset: 'Reset',
  rest: {
    day: '{{count}} rest day',
    days: '{{count}} rest days',
    hour: '{{count}} hour',
    hours: '{{count}} hours',
    noRestDay: 'No rest day',
  },
  restHours: 'Rest {{count}}h',
  setMissingData_one: 'One completed set has incomplete details!',
  setMissingData_other: '{{count}} completed sets have missing details!',
  sets_completed: 'Sets Completed',
  sets_total: 'Total Sets: {{count}}',
  skip: 'Skip',
  status,
  summary: {
    averageRpe: 'Average Rpe',
    label: 'Summary',
    tonnage: 'Tonnage',
    totalReps: 'Total Reps',
    totalSets: 'Total Sets',
    totalTimedSets: 'Total Timed Sets',
    trend: 'Compared to previous workout',
  },
  template: {
    many: 'Template Workouts',
    search_zero: 'No results',
    search_zero_subtitle: 'No template workout found.',
    zero: 'No workout templates available.',
    zero_subtitle: 'Create a template to create routines quicker',
  },
  template_one: 'Template workout',
  template_other: 'Template workouts',
  unSkip: 'Un-skip',
  workoutBuilder: 'Workout Builder',
  workouts: 'Workouts',
  workouts_added_zero: 'No workouts added yet',
  workouts_completed: 'Workouts Completed',
  workouts_completed_recently: 'Recently completed workouts',
  workouts_one: 'Workout',
  workouts_other: '{{count}} workouts',
  workouts_zero: 'No workouts available',
};

export default translationsWorkout;
