/**
 * @file We use ids for toast contents.
 * The contents can be found in {@see file://./../i18n/en/toasts.ts}
 */

/**
 * Ids for success toasts
 */
export enum ToastSuccessId {
  ClientCreate = 'Success_ClientCreate',
  ClientDelete = 'Success_ClientDelete',
  ClientRoutineGenerated = 'Success_ClientRoutineGenerated',
  PasswordChange = 'Success_PasswordChange',
  PasswordForgot = 'Success_PasswordForgot',
  PasswordReset = 'Success_PasswordReset',
  ResendVerificationCode = 'Success_ResendVerificationCode',
  SignUp = 'Success_SignUp',
  TemplateRoutineGenerated = 'Success_TemplateRoutineGenerated',
  TemplateWorkoutGenerated = 'Success_TemplateWorkoutGenerated',
}

/**
 * Ids for error toasts
 */
export enum ToastErrorId {
  General = 'Error_General',
  LoginUserClientForbidden = 'Error_LoginUserClientForbidden',
  PasswordChange = 'Error_PasswordChange',
  ResendVerificationCode = 'Error_ResendVerificationCode',
}

/**
 * Ids for warning toasts
 */
export enum ToastWarningId {
  InternetConnection = 'Warning_InternetConnection',
}

/**
 * Ids for info toasts
 */
export enum ToastInfoId {
  InternetConnection = 'Info_InternetConnection',
}
