import { RoutineStatus } from '../../features/routine/@client/types';

const translationsRoutines = {
  add: 'Add Routine',
  cycle_add: 'Add Cycle',
  cycle_delete: 'Delete Cycle',
  cycle_duplicate: 'Duplicate Cycle',
  cycle_edit: 'Edit Cycle',
  cycle_move: 'Reorder this cycle',
  cycleEmpty: 'No workouts available for this cycle',
  cycles: 'Cycles',
  cycles_one: 'Cycle',
  cycles_other: '{{count}} cycles',
  delete: 'Delete Routine',
  edit: 'Edit Routine',
  next_up: 'Next Up!',
  routineCycles: 'Routine Cycles',
  routines: 'Routines',
  routines_one: 'Routine',
  routines_other: '{{count}} Routines',
  routines_status_zero: 'No routines in this category',
  routines_zero: 'No routines',
  status: {
    [RoutineStatus.Completed]: {
      description:
        'The routines are visible in the mobile app, but can be hidden if necessary.',
      label: 'Completed',
      title: 'Completed',
    },
    [RoutineStatus.Draft]: {
      description: 'The routines are only visible here until you publish them.',
      label: 'Draft',
      title: 'Draft',
    },
    [RoutineStatus.InProgress]: {
      description:
        'The routines are visible in the mobile app and can be used within the app.',
      label: 'In Progress',
      title: 'Routines',
    },
    [RoutineStatus.Ready]: {
      description:
        'After completing a routine, your next routine will automatically move up to "In Progress".',
      label: 'Ready',
      title: 'Ready to Go',
    },
  },
  template: {
    delete: 'Delete Routine Template',
    info: 'Routine Template info',
    label: 'Routine Templates',
    many: 'Template Workouts',
    search_zero: 'No results',
    search_zero_subtitle: 'No template workout found.',
    zero: 'No workout templates available.',
    zero_subtitle: 'Create a template to create routines quicker',
  },
  totalWorkouts: 'Total workouts: {{count}}',
  workouts_compare: 'Compare workouts',
};

export default translationsRoutines;
