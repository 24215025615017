import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import { queryClient } from './config/react-query';
import useIsMobile from './hooks/useIsMobile';
import reportWebVitals from './reportWebVitals';
import HistoryProvider from './router/history';
import { mantineTheme } from './theme';
import { initMixpanel } from './utils/analytics';
import { initSentry } from './utils/sentry';

const rootEl = document.getElementById('root') as HTMLElement;

const root = createRoot(rootEl);

initSentry();
initMixpanel();

/**
 * Main App Component
 * @returns Main Component
 */
export function Main() {
  const isMobile = useIsMobile();

  return (
    <BrowserRouter>
      <MantineProvider theme={mantineTheme}>
        <QueryClientProvider client={queryClient}>
          <Notifications position={isMobile ? 'top-right' : 'bottom-right'} />
          <HistoryProvider>
            <App />
          </HistoryProvider>
        </QueryClientProvider>
      </MantineProvider>
    </BrowserRouter>
  );
}

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
