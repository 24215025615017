/**
 * @file A React hook for tracking the dimensions of the window and letting us
 * know if we are on mobile size screen
 */
import { px } from '@mantine/core';
import { useWindowEvent } from '@mantine/hooks';
import { useState } from 'react';

import { vars } from '../theme';

/**
 * A React hook for tracking whether we are in mobile ui
 * @returns Whether window width is mobile
 */
const useIsMobile = () => {
  const mobileBreakpoint = px(vars.breakpoints.sm) as number;

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < mobileBreakpoint,
  );

  /**
   * Event handler to update the dimensions state when the window is resized.
   */
  useWindowEvent('resize', () => {
    setIsMobile(prev => {
      const nextIsMobile = window.innerWidth < mobileBreakpoint;

      if (nextIsMobile !== prev) {
        return nextIsMobile;
      }
      return prev;
    });
  });

  return isMobile;
};

export default useIsMobile;
