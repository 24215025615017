export enum ConversionType {
  ClientRoutineToTemplateRoutine = 'ClientRoutineToTemplateRoutine',
  ClientWorkoutToStandaloneTemplateWorkout = 'ClientWorkoutToStandaloneTemplateWorkout',
  StandaloneTemplateWorkoutToClientWorkout = 'StandaloneTemplateWorkoutToClientWorkout',
  StandaloneTemplateWorkoutToTemplateRoutineWorkout = 'StandaloneTemplateWorkoutToTemplateRoutineWorkout',
  TemplateRoutineToClientRoutine = 'TemplateRoutineToClientRoutine',
  TemplateRoutineWorkoutToStandaloneTemplateWorkout = 'TemplateRoutineWorkoutToStandaloneTemplateWorkout',
}

interface RequestConversionsMap {
  [ConversionType.ClientRoutineToTemplateRoutine]: {
    conversionType: ConversionType.ClientRoutineToTemplateRoutine;
    params: {
      position: null | number;
      routineId: string;
    };
  };
  [ConversionType.ClientWorkoutToStandaloneTemplateWorkout]: {
    conversionType: ConversionType.ClientWorkoutToStandaloneTemplateWorkout;
    params: {
      workoutId: string;
    };
  };
  [ConversionType.StandaloneTemplateWorkoutToClientWorkout]: {
    conversionType: ConversionType.StandaloneTemplateWorkoutToClientWorkout;
    params: {
      cycleId: number;
      routineId: string;
      templateWorkoutId: string;
    };
  };
  [ConversionType.StandaloneTemplateWorkoutToTemplateRoutineWorkout]: {
    conversionType: ConversionType.StandaloneTemplateWorkoutToTemplateRoutineWorkout;
    params: {
      cycleId: number;
      templateRoutineId: string;
      templateWorkoutId: string;
    };
  };
  [ConversionType.TemplateRoutineToClientRoutine]: {
    conversionType: ConversionType.TemplateRoutineToClientRoutine;
    params: {
      clientId: string;
      templateRoutineId: string;
    };
  };
  [ConversionType.TemplateRoutineWorkoutToStandaloneTemplateWorkout]: {
    conversionType: ConversionType.TemplateRoutineWorkoutToStandaloneTemplateWorkout;
    params: {
      workoutId: string;
    };
  };
}

export type RequestConversionType<T extends keyof RequestConversionsMap> =
  RequestConversionsMap[T];

export type RequestConversion =
  | RequestConversionType<ConversionType.ClientRoutineToTemplateRoutine>
  | RequestConversionType<ConversionType.ClientWorkoutToStandaloneTemplateWorkout>
  | RequestConversionType<ConversionType.StandaloneTemplateWorkoutToClientWorkout>
  | RequestConversionType<ConversionType.StandaloneTemplateWorkoutToTemplateRoutineWorkout>
  | RequestConversionType<ConversionType.TemplateRoutineToClientRoutine>
  | RequestConversionType<ConversionType.TemplateRoutineWorkoutToStandaloneTemplateWorkout>;
