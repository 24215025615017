import {
  CommonExerciseInstruction,
  CommonSetAttributes,
  SetProvidedDetails,
} from './exercise-instruction';

export interface FormExerciseInstruction
  extends Omit<CommonExerciseInstruction, 'createdBy' | 'exerciseId' | 'sets'> {
  exerciseId: null | string;
  sets: CommonSetAttributes[];
}

export enum SetFormField {
  Comments = 'comments',
  IsOptional = 'isOptional',
  Rest = 'rest',
  Rpe = 'rpe',
  Sets = 'sets',
  Tempo = 'tempo',
  Volume = 'volume',
  VolumeUnit = 'volumeUnit',
  Weight = 'weight',
}

export interface SetForm {
  [SetFormField.Comments]: SetProvidedDetails['comments'];
  [SetFormField.IsOptional]: CommonSetAttributes['isOptional'] | null;
  [SetFormField.Rest]: null | SetProvidedDetails['rest'];
  [SetFormField.Rpe]: null | SetProvidedDetails['rpe'];
  [SetFormField.Tempo]: null | string;
  [SetFormField.Volume]: string;
  [SetFormField.VolumeUnit]: CommonSetAttributes['volumeUnit'];
  [SetFormField.Weight]: null | number;
  id: string;
}
