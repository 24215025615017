// ===========================//
// EXERCISE
// ===========================//

export enum ExerciseVolumeUnit {
  Distance = 'Distance',
  Reps = 'Reps',
  Time = 'Time',
}

export enum ExerciseType {
  Compound = 'Compound', // Multi joint exercises
  Isolation = 'Isolation', // Single joint exercises
}

export enum ExerciseExecutionType {
  Dynamic = 'Dynamic',
  Static = 'Static',
}

export enum ExerciseComplexity {
  High = 4, // Squat, Deadlift, Russian Stepup, DB bench press, Press
  Low = 2, // Leg curl, Lat pulldown, Leg Press, Pullover, Rotator cuff
  Medium = 3, // Bench Press, Lunges, Step-ups,
  VeryHigh = 5, // Olympic lifts, Olympic lift variations, Plyometrics
  VeryLow = 1, // Leg extension, Calves, Frog Abs, Machines, Forearms
}

export enum MuscleGroup {
  Abductors = 'Abductors',
  Abs = 'Abs',
  Adductors = 'Adductors',
  Biceps = 'Biceps',
  Calves = 'Calves',
  Chest = 'Chest',
  Forearm = 'Forearm',
  Glutes = 'Glutes',
  Hamstring = 'Hamstring',
  HipFlexors = 'HipFlexors',
  Lats = 'Lats',
  LowerBack = 'LowerBack',
  MidBack = 'MidBack',
  Neck = 'Neck',
  Quads = 'Quads',
  RotatorCuff = 'RotatorCuff',
  Shins = 'Shins',
  Shoulder = 'Shoulder',
  Traps = 'Traps',
  Triceps = 'Triceps',
}

export interface Exercise {
  bodyWeightPercentage: null | number;
  comments: string[];
  complexity: ExerciseComplexity;
  createdAt: Date;
  createdBy: string;
  deletedAt: Date | null;
  executionType: ExerciseExecutionType;
  externalUrl: null | string;
  gifUrl: null | string;
  id: string;
  instruction: null | string;
  isPublic: boolean;
  isUnilateral: boolean;
  name: string;
  primaryMuscles: MuscleGroup[];
  secondaryMuscles: MuscleGroup[];
  thumbnail: null | string;
  type: ExerciseType;
  updatedAt: Date;
  variation: null | string;
  videoUrl: null | string;
  volumeUnits: ExerciseVolumeUnit[];
}

export type ExerciseCategory = 'company' | 'my' | 'strenco';

export type ExerciseList = Record<ExerciseCategory, Exercise[]>;
