export enum UserType {
  Admin = 'Admin',
  Client = 'Client',
  Coach = 'Coach',
  CompanyCoach = 'CompanyCoach',
  CompanyOwner = 'CompanyOwner',
  SuperAdmin = 'SuperAdmin',
}

export interface User {
  avatarUrl: null | string;
  coachId: null | string;
  email: string;
  emailVerified: string;
  firstName: string;
  id: string;
  lastName: string;
  username: string;
  userType: UserType;
}
