import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from 'aws-amplify/auth';

import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { getCurrentCognitoUser } from '../../../../utils/cognito';

export const useUser = () => {
  const user = getCurrentUser();

  return useQuery({
    enabled: user !== null,
    queryFn: getCurrentCognitoUser,
    queryKey: QueryKey.User(),
  });
};

export const invalidateUser = () =>
  queryClient.invalidateQueries({ queryKey: QueryKey.User() });
