export enum ApiError {
  AuthTokenExpired = 'AuthTokenExpired',
  BadRequest = 'BadRequest',
  ClientBelongsToAnotherCoach = 'ClientBelongsToAnotherCoach',
  Conflict = 'Conflict',
  CycleIdIsRequired = 'CycleIdIsRequired',
  CycleIdNotAllowed = 'CycleIdNotAllowed',
  CycleNotFound = 'CycleNotFound',
  DatabaseError = 'DatabaseError',
  DatabaseValidation = 'DatabaseValidation',
  Default = 'SomethingWentWrong',
  DuplicateGoal = 'DuplicateGoal',
  DuplicateMuscleGroup = 'DuplicateMuscleGroup',
  ExerciseDeleteUsedInExerciseInstructions = 'ExerciseDeleteUsedInExerciseInstructions',
  ExerciseInstructionNotFound = 'ExerciseInstructionNotFound',
  Forbidden = 'Forbidden',
  InvalidCredentials = 'InvalidCredentials',
  InvalidGoal = 'InvalidGoal',
  InvalidMuscleGroup = 'InvalidMuscleGroup',
  NotAuthorized = 'NotAuthorized',
  NotFound = 'NotFound',
  RequestValidation = 'RequestValidation',
  RoutineNotFound = 'RoutineNotFound',
  SetNotFound = 'SetNotFound',
  Unprocessable = 'Unprocessable',
  UserAlreadyExists = 'UserAlreadyExists',
  UserAlreadyInvited = 'UserAlreadyInvited',
  UsernameAlreadyExists = 'UsernameAlreadyExists',
  WorkoutNotFound = 'WorkoutNotFound',
}
