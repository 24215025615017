import {
  ToastErrorId,
  ToastInfoId,
  ToastSuccessId,
  ToastWarningId,
} from '../../constants/toast-ids';

interface Props {
  text: string;
  title: string;
}

const translationsError: Record<ToastErrorId, Props> = {
  [ToastErrorId.General]: {
    text: 'Try later or contact support.',
    title: 'Something went wrong',
  },
  [ToastErrorId.LoginUserClientForbidden]: {
    text: 'Access to this app is restricted to coaches only.',
    title: 'Access Forbidden',
  },
  [ToastErrorId.PasswordChange]: {
    text: 'The password you provided is incorrect. Please check your password and try again.',
    title: 'Invalid Password',
  },
  [ToastErrorId.ResendVerificationCode]: {
    text: 'An error occurred while sending the verification code.',
    title: 'Something Went Wrong',
  },
};

const translationsSuccess: Record<ToastSuccessId, Props> = {
  [ToastSuccessId.ClientCreate]: {
    text: 'The client has been successfully added. They will receive an email with further instructions.',
    title: 'Client Added Successfully',
  },
  [ToastSuccessId.ClientDelete]: {
    text: 'The client has been deleted, and they will no longer have access to the mobile app.',
    title: 'Client Deleted',
  },
  [ToastSuccessId.ClientRoutineGenerated]: {
    text: 'A draft routine was created for your client.',
    title: 'Client Routine Created',
  },
  [ToastSuccessId.PasswordChange]: {
    text: 'Your password has been changed successfully. You can now use your new password to log in.',
    title: 'Password Changed Successfully',
  },
  [ToastSuccessId.PasswordForgot]: {
    text: 'A password reset email has been sent to your account. Please check your email to reset your password.',
    title: 'Password Reset Email Sent',
  },
  [ToastSuccessId.PasswordReset]: {
    text: 'Your password has been successfully reset. You can now use your new password to sign in.',
    title: 'Password Reset Successful',
  },
  [ToastSuccessId.ResendVerificationCode]: {
    text: 'A verification code has been successfully resent to your email. Please check your inbox.',
    title: 'Verification Code Resent',
  },
  [ToastSuccessId.SignUp]: {
    text: 'Your sign-up was successful. Please check your email for a verification link.',
    title: 'Sign Up Successful',
  },
  [ToastSuccessId.TemplateRoutineGenerated]: {
    text: 'Your routine template is now available. Use it to generate routines quicker!',
    title: 'Routine Template Created',
  },
  [ToastSuccessId.TemplateWorkoutGenerated]: {
    text: 'Your workout template is now available. Use it to build workouts and routines faster!',
    title: 'Workout Template Created',
  },
};

const translationsWarning: Record<ToastWarningId, Props> = {
  [ToastWarningId.InternetConnection]: {
    text: 'Uh-oh! It seems like your internet connection is down.',
    title: 'Connection Lost',
  },
};

const translationsInfo: Record<ToastInfoId, Props> = {
  [ToastInfoId.InternetConnection]: {
    text: 'You can continue using the app without any issues.',
    title: 'Connected to the Internet',
  },
};

const translationsToast = {
  ...translationsError,
  ...translationsSuccess,
  ...translationsWarning,
  ...translationsInfo,
};

export default translationsToast;
