import { Exercise, MuscleGroup } from '../types';
import { Difficulty, Goal } from '../types/common';
import {
  CommonExerciseInstruction,
  CommonSetAttributes,
} from '../types/exercise-instruction';
import { CommonRoutineAttributes, RoutineCycle } from '../types/routine';
import { CommonWorkoutAttributes } from '../types/workout';

export enum MeasureSystem {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export enum MeasureUnit {
  Count = 'Count', // Reps
  Kilogram = 'Kilogram', // Weight
  Meter = 'Meter', // Distance
  Pound = 'Pound', // Weight
  Seconds = 'Seconds', // Time
  Yard = 'Yard', // Distance
}

export enum SetMeasureType {
  Distance = 'Distance',
  Reps = 'Reps',
  Time = 'Time',
  Weight = 'Weight',
}

export interface SetAccomplishedMeasure {
  type: SetMeasureType;
  unit: MeasureUnit;
  value: null | number;
}

export interface SetAccomplishedDetails {
  accomplishedAt: string;
  comment: null | string;
  measures: SetAccomplishedMeasure[];
  rpe: null | number;
}

export interface SetPrepare {
  weight: number;
}
export interface ClientSetAttributes extends CommonSetAttributes {
  accomplished: null | SetAccomplishedDetails;
  isSkipped?: boolean;
  prepare?: null | SetPrepare;
}

export interface ClientExerciseInstruction extends CommonExerciseInstruction {
  clientId: string;
  coachId: string;
  exercise: Exercise;
  sets: ClientSetAttributes[];
}

export enum WorkoutStatus {
  Completed = 'Completed',
  CompletedWithMissingData = 'CompletedWithMissingData',
  InProgress = 'InProgress',
  None = 'None',
  Skipped = 'Skipped',
}

export interface ClientWorkout extends Required<CommonWorkoutAttributes> {
  bodyWeight: null | number;
  clientId: string;
  coachId: string;
  completedAt: null | string;
  cycleId: number;
  exerciseInstructions: ClientExerciseInstruction[];
  position: number;
  routineId: string;
  status: WorkoutStatus;
}

export enum RoutineStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Ready = 'Ready',
}

export interface ClientRoutineFull extends CommonRoutineAttributes {
  clientDetails: {
    avatarUrl: null | string;
    firstName: string;
    lastName: string;
  };
  clientId: string;
  coachId: null | string;
  completedAt: null | string;
  status: RoutineStatus;
  workouts: ClientWorkout[];
}

export interface ClientRoutineList {
  clientId: string;
  coachId: null | string;
  completedAt: null | string;
  cycles: RoutineCycle[];
  difficulty: Difficulty;
  id: string;
  name: string;
  position: number;
  primaryGoals: Goal[];
  secondaryGoals: Goal[];
  status: RoutineStatus;
  workouts: Array<{
    clientId: string;
    completedAt: ClientWorkout['completedAt'];
    cycleId: number;
    id: string;
    name: string;
    position: number;
    restHours: number;
    routineId: string;
    status: WorkoutStatus;
  }>;
}

/**
 * WORKOUT SUMMARY START
 */
export interface SetRef {
  exerciseInstructionId: string;
  groupId: number;
  set: ClientSetAttributes;
}

export interface IWorkoutSummary {
  avgRpe: {
    accomplished: number;
    provided: number;
  };
  exercisesCount: number;
  // [primaryMuscle, secondaryMuscle] TODO: Move to separate hook
  musclesWorked: Partial<Record<MuscleGroup, [number, number]>>;
  notAccomplishedSets: SetRef[];
  reps: {
    accomplished: number;
    providedAvg: number;
    providedMax: number;
    providedMin: number;
  };
  sets: {
    accomplished: number;
    provided: number;
    skipped: number;
  };
  setsWithMissingData: SetRef[];
  timeS: {
    accomplished: number;
    providedAvg: number;
    providedMax: number;
    providedMin: number;
  };
  tonnage: {
    unit: MeasureUnit.Kilogram | MeasureUnit.Pound;
    value: number;
  } | null;
}

interface CommonReps {
  bodyWeightPercentage: Exercise['bodyWeightPercentage'];
  isUnilateral: Exercise['isUnilateral'];
}

export interface SetVolume extends CommonReps {
  reps: null | number;
  time: null | number;
  unit: MeasureUnit.Kilogram | MeasureUnit.Pound | null;
  weight: null | number;
}

export interface VolumeRepsValid extends CommonReps {
  reps: number;
  time: number;
  unit: MeasureUnit.Kilogram | MeasureUnit.Pound;
  weight: number;
}

/**
 * WORKOUT SUMMARY END
 */
