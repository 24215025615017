import { ExerciseVolumeUnit } from './exercise';

export enum SetParameter {
  Comments = 'comments',
  Rest = 'rest',
  Rpe = 'rpe',
  Tempo = 'tempo',
  Volume = 'volume',
}

export interface SetProvidedDetails {
  [SetParameter.Comments]: string[];
  [SetParameter.Rest]: null | number;
  [SetParameter.Rpe]: null | number;
  [SetParameter.Tempo]: null | string[];
  [SetParameter.Volume]: [number, number];
}

export interface CommonSetAttributes {
  readonly id: string;
  isOptional: boolean;
  provided: SetProvidedDetails;
  volumeUnit: ExerciseVolumeUnit;
}

export interface CommonExerciseInstruction {
  comments: string[];
  createdBy: string;
  exerciseId: string;
  groupId: number;
  readonly id: string;
  position: number;
  sets: CommonSetAttributes[];
  workoutId: string;
}

export interface MoveExerciseInstruction {
  exerciseInstructionId: string;
  /**
   * Destination groupId
   */
  groupId: number;

  /**
   * Index position after exercise instruction was moved
   */
  position: number;
  routineId: string;
  workoutId: string;
}
