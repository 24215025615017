import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import format from './helpers/format';

export const resources = {
  en: { ...en, translation: en },
};

export const init18n = () =>
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      compatibilityJSON: 'v3',
      debug: false,
      // if you're using a language detector, do not define the lng option
      fallbackLng: 'en',
      // if you're using a language detector, do not define the lng option
      interpolation: {
        escapeValue: false, // react already safes from xss
        format,
      },
      lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      resources,
      returnNull: false,
      supportedLngs: ['en'],
    });

export const translate = i18n.t;
export const selectedLanguage = i18n.language;
