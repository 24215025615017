/** @file Contains utils for user */
import { StorageKeys, StorageService } from '../../../utils/storage';
import { User, UserType } from '../types';

/**
 * Get the current user object from local storage.
 * @returns The user object from local storage.
 */
export const getCurrentStorageUser = (): null | User => {
  const storageUser = StorageService.read<User>(StorageKeys.User);

  if (storageUser === null) {
    return null;
  }

  return storageUser;
};
/**
 * Get the user type of logged in user
 * @returns The user type if logged in, otherwise null
 */
export const getUserType = (): null | UserType => {
  const user = getCurrentStorageUser();

  return user?.userType ?? null;
};

/**
 * Get whether the logged in user is CoachPremium
 * @returns Whether the user is CoachPremium
 */
export const isCoachPremium = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.userType === UserType.Coach;
};

/**
 * Get whether the logged in user is CompanyOwner
 * @returns Whether the user is CompanyOwner
 */
export const isCompanyOwner = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.userType === UserType.CompanyOwner;
};

/**
 * Get whether the logged in user is CompanyOwner
 * @returns Whether the user is CompanyOwner
 */
export const isCompanyCoach = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.userType === UserType.CompanyCoach;
};

/**
 * Get whether an id equals to the current user id
 * @param id Id we want to compare
 * @returns  Whether id is current user id
 */
export const isCurrentUserId = (id: null | string): boolean => {
  const user = getCurrentStorageUser();

  return user !== null && user.id === id;
};

/**
 * Get whether the logged in user is SuperAdmin
 * @returns Whether the user is SuperAdmin
 */
export const isSuperAdmin = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.userType === UserType.SuperAdmin;
};
