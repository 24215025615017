import { FieldId } from '../../constants/forms/form-fields';

interface FormFieldProp {
  /**
   * Description text below the label
   */
  description?: string;
  /**
   * Tooltip or some details
   */
  info?: string;
  /**
   * The label
   */
  label: string;
  /**
   * The placeholder
   */
  placeholder?: string;
}

const translationFormField: Record<FieldId, FormFieldProp> = {
  [FieldId.BodyWeightPercentage]: {
    info: 'How much % of bodyweight should be included in the tonnage.',
    label: 'Bodyweight Percentage',
  },
  [FieldId.CoachingFormat]: {
    info: 'How you are going to train the client',
    label: 'Coaching Format',
  },
  [FieldId.Comments]: {
    label: 'Comments',
    placeholder: 'Enter new comment',
  },
  [FieldId.Complexity]: {
    label: 'Exercise Complexity',
  },
  [FieldId.ConfirmPassword]: {
    label: 'Confirm Password',
  },
  [FieldId.CycleName]: {
    label: 'Cycle Name',
  },
  [FieldId.Difficulty]: {
    label: 'Difficulty',
  },
  [FieldId.Email]: {
    label: 'Email',
  },
  [FieldId.ExecutionType]: {
    label: 'Execution Type',
  },
  [FieldId.ExerciseSearch]: {
    label: 'Search',
    placeholder: 'Search by name, variant or muscle group',
  },
  [FieldId.ExerciseType]: {
    info: 'Compound are multi-joint exercises like Bench Press and Squat. Isolation are single joint exercises like DB Fly, Leg Extensions',
    label: 'Exercise Type',
  },
  [FieldId.ExerciseViewSelect]: {
    label: 'List exercises',
  },
  [FieldId.ExternalUrl]: {
    label: 'External URL',
    placeholder: 'https://youtube.com/...',
  },
  [FieldId.FirstName]: {
    label: 'First Name',
  },
  [FieldId.Instruction]: {
    label: 'Instructions',
  },
  [FieldId.IsUnilateral]: {
    label: 'Is Unilateral',
  },
  [FieldId.LastName]: {
    label: 'Last Name',
  },
  [FieldId.Login]: {
    label: 'Email' /** @todo - add username */,
  },
  [FieldId.Name]: {
    label: 'Name',
  },
  [FieldId.NewPassword]: {
    label: 'New Password',
  },
  [FieldId.OldPassword]: { label: 'Old Password' },
  [FieldId.Password]: { label: 'Password' },
  [FieldId.PrimaryGoals]: { label: 'Primary Goals' },
  [FieldId.PrimaryMuscles]: { label: 'Primary Muscles' },
  [FieldId.RestDetails]: { label: 'Rest Details' },
  [FieldId.RestHours]: { label: 'Rest Time' },
  [FieldId.SecondaryGoals]: { label: 'Secondary Goals' },
  [FieldId.SecondaryMuscles]: { label: 'Secondary Muscles' },
  [FieldId.SelectClient]: { label: 'Select Clients' },
  [FieldId.SelectExercise]: { label: 'Select Exercise' },
  [FieldId.SetComments]: {
    label: 'Comments',
  },
  [FieldId.SetIsOptional]: {
    label: 'Optional',
  },
  [FieldId.SetRest]: {
    label: 'Rest',
  },
  [FieldId.SetRpe]: {
    label: 'Rpe',
  },
  [FieldId.SetSets]: {
    label: 'Set',
  },
  [FieldId.SetTempo]: {
    label: 'Tempo',
  },
  [FieldId.SetVolume]: {
    label: 'Volume',
  },
  [FieldId.SetVolumeUnit]: {
    label: 'Volume',
  },
  [FieldId.SetWeight]: {
    label: 'Weight',
  },
  [FieldId.TemporaryPassword]: { label: 'Temporary Password' },
  [FieldId.Terms]: { label: 'I accept terms and conditions' },
  [FieldId.Thumbnail]: {
    label: 'Thumbnail',
    placeholder: 'Drag image here or click to select',
  },
  [FieldId.Username]: { label: 'Username' },
  [FieldId.Variation]: { label: 'Variation' },
  [FieldId.Video]: {
    label: 'Video',
    placeholder: 'Drag video here or click to select',
  },
  [FieldId.VolumeUnits]: { label: 'Volume Units' },
  [FieldId.WorkoutCompareColumnsFilter]: {
    description: 'Only selected columns will be visible.',
    label: 'Columns',
  },
  [FieldId.WorkoutCompareSelect]: {
    description:
      'Select one or more workouts from the list below to compare their results side by side.',
    label: 'Select Workouts for Comparison',
    placeholder: 'Pick Workout',
  },
};

export default translationFormField;
