import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Memoized } from '../../components/utils';

interface IHistoryContext {
  goBack: (fallbackBackUrl: null | string) => void;
}

const HistoryContext = React.createContext<IHistoryContext | undefined>(
  undefined,
);

// eslint-disable-next-line react-refresh/only-export-components
export const useHistoryContext = (): IHistoryContext => {
  const context = useContext(HistoryContext);

  if (!context) {
    throw new Error('useHistoryContext cannot be used without HistoryContext');
  }

  return context;
};

export default function HistoryProvider({ children }: PropsWithChildren) {
  const location = useLocation();
  const navigate = useNavigate();
  const history = useRef<Record<string, string[]>>({});
  const { pathname } = location;

  const from = location.state?.from as string | undefined;

  useEffect(() => {
    if (from === undefined) {
      return;
    }

    const stack = from.split('/')[1];

    if (history.current[stack]?.at(-1) === from) {
      return;
    }

    if (history.current[stack] === undefined) {
      history.current[stack] = [from];
    } else {
      history.current[stack].push(from);
    }
  }, [from, pathname]);

  const goBack = useCallback(
    (fallbackBackUrl: null | string) => {
      const stack = pathname.split('/')[1];
      const route = history.current[stack]?.pop() ?? fallbackBackUrl;

      if (route) {
        navigate(route);
      }
    },
    [navigate, pathname],
  );

  return (
    <HistoryContext.Provider value={{ goBack }}>
      <Memoized>{children}</Memoized>
    </HistoryContext.Provider>
  );
}
