/**
 * @file Contains translations for exercise instructions
 */
import {
  MeasureUnit,
  SetMeasureType,
} from '../../features/routine/@client/types';
import { SetFormField, SetParameter } from '../../features/routine/types';

interface SetParameterProps {
  details?: string[];
  info: string;
  label: string;
}

const measureType: Record<SetMeasureType, { label: string }> = {
  [SetMeasureType.Distance]: {
    label: 'Distance',
  },
  [SetMeasureType.Reps]: {
    label: 'Reps',
  },
  [SetMeasureType.Time]: {
    label: 'Time',
  },
  [SetMeasureType.Weight]: {
    label: 'Weight',
  },
};

const measureUnit: Record<MeasureUnit, { label: string; short: string }> = {
  [MeasureUnit.Count]: {
    label: 'Count',
    short: 'ct',
  },
  [MeasureUnit.Kilogram]: {
    label: 'Kilograms',
    short: 'kg',
  },
  [MeasureUnit.Meter]: {
    label: 'Meters',
    short: 'm',
  },
  [MeasureUnit.Pound]: {
    label: 'Pounds',
    short: 'lbs',
  },
  [MeasureUnit.Seconds]: {
    label: 'Seconds',
    short: 's',
  },
  [MeasureUnit.Yard]: {
    label: 'Yards',
    short: 'yd',
  },
};

const setParameter: Record<SetParameter, SetParameterProps> = {
  [SetParameter.Comments]: {
    info: 'Comments related to this set',
    label: 'Comments',
  },
  [SetParameter.Rest]: {
    info: 'How much rest you have after completing the set',
    label: 'Rest',
  },
  [SetParameter.Rpe]: {
    info: 'Rate of Perceived Exertion (RPE) is a way to express how hard or easy your workout feels to you. On a scale from 1 to 10, with 1 being very light effort and 10 being the most intense.',
    label: 'Rpe',
  },
  [SetParameter.Tempo]: {
    details: [
      'The first number (4) represents the eccentric phase, which is the lowering or resisting phase of the exercise. You should take 4 seconds to complete this part',
      'The second number (0) signifies that there is no pause or rest at the midpoint between the eccentric and concentric phases.',
      'The third number (X) represents the concentric phase, which is the lifting or pushing phase of the exercise. X means that you should execute the movement as fast as possible',
      'The fourth number (1) indicates that there is a one second pause at the midpoint between the concentric and eccentric phases.',
    ],
    info: 'Tempo is a specific pattern for the timing of different phases in an exercise (4,0,X,2)',
    label: 'Tempo',
  },
  [SetParameter.Volume]: {
    info: 'Represents the amount of work performed. The amount can be specified using Reps, Time or Distance',
    label: 'Volume',
  },
};

const setFormField: Record<SetFormField, { errorInfo: string; info: string }> =
  {
    [SetFormField.Comments]: {
      errorInfo: '',
      info: 'Comments related to this set',
    },
    [SetFormField.IsOptional]: {
      errorInfo: '',
      info: 'If checked, this set will not be required in order to mark a workout as completed',
    },
    [SetFormField.Rest]: {
      errorInfo: 'Please use a number value as seconds',
      info: 'How much rest you have after completing the set',
    },
    [SetFormField.Rpe]: {
      errorInfo:
        'Only values between 1 and 10 are allowed (including 1 and 10).',
      info: 'Rate of Perceived Exertion (RPE) is a way to express how hard or easy your workout feels to you. On a scale from 1 to 10, with 1 being very light effort and 10 being the most intense.',
    },
    [SetFormField.Sets]: {
      errorInfo:
        'Only range values (1-n) or comma separated sets (1,2,3...n) are allowed.',
      info: 'Enter set, range (1-5) or multiple (1,2,4,5...)',
    },
    [SetFormField.Tempo]: {
      errorInfo:
        'Tempo must contain 4 comma separated values (number > 0 or X).',
      info: `Tempo is a specific pattern for the timing of different phases in an exercise (4,0,X,2). ${[
        'The first number (4) represents the eccentric phase, which is the lowering or resisting phase of the exercise. You should take 4 seconds to complete this part',
        'The second number (0) signifies that there is no pause or rest at the midpoint between the eccentric and concentric phases.',
        'The third number (X) represents the concentric phase, which is the lifting or pushing phase of the exercise. X means that you should execute the movement as fast as possible',
        'The fourth number (1) indicates that there is a one second pause at the midpoint between the concentric and eccentric phases.',
      ].join('. ')}`,
    },
    [SetFormField.Volume]: {
      errorInfo: '',
      info: 'Represents the amount of work performed. The amount can be specified using Reps, Time or Distance',
    },
    [SetFormField.VolumeUnit]: {
      errorInfo: '',
      info: 'Select if you want reps or time to measure volume',
    },
    [SetFormField.Weight]: {
      errorInfo: '',
      info: 'Prepare the weight for this set. It will be preselected and visible in the mobile app.',
    },
  };

const translationsExerciseInstructions = {
  comments_set: 'Set comments',
  measureType,
  measureUnit,
  newSet: 'New Set',
  reorder: 'Reorder {{value}}',
  selectAtLeastOne: 'Select at least one exercise instruction (A1, A2..)',
  selectExercise: 'Select Exercise',
  series: 'Series {{value}}',
  series_delete: 'Delete series {{value}}',
  series_duplicate: 'Duplicate series {{value}}',
  series_reorder: 'Reorder series {{value}}',
  set_note: 'Set Note',
  set_note_one: 'Client left a note',
  set_reorder: 'Reorder set (move up or down)',
  setBuilder: {
    label: 'Set Builder',
  },
  setFormField,
  setParameter,
  sets: 'Sets',
  sets_missingData_one: 'Missing data',
  sets_one: 'Set',
  sets_other: '{{count}} sets',
  sets_skipped: 'Sets skipped',
  sets_skipped_one: 'One Set Skipped',
  sets_skipped_other: '{{count}} sets skipped',
  sets_zero: 'No sets available',
  weight_prepared: 'Prepared weight',
};

export default translationsExerciseInstructions;
