/** @file Contains translations for form validation errors */
import { FormValidationError } from '../../constants/forms/form-validation-errors';

const translationFormFieldValidationError = {
  email_invalid: 'Enter a valid email address.',
  email_required: 'Email is required',
  [FormValidationError.Invalid]: {
    info: '',
    message: 'is invalid',
  },
  [FormValidationError.InvalidEmail]: {
    info: '',
    message: 'Please enter a valid email address',
  },
  [FormValidationError.InvalidPassword]: {
    info: '',
    message:
      'Password must be at least 8 characters long and include all of the following: an uppercase letter, a lowercase letter, a number, and a special character (!@#$%^&*)',
  },
  [FormValidationError.InvalidPasswordNoMatch]: {
    info: '',
    message: 'Passwords do not match',
  },
  [FormValidationError.Required]: {
    info: '',
    message: 'is required',
  },
  invalid: 'Invalid',
  password: {
    base: 'Password must contain at least one',
    digit: 'digit.',
    lowercase: 'lowercase letter',
    minLength: 'Password must be at least 8 characters long.',
    mismatch: 'Passwords do not match.',
    specialChar: 'special character (!@#$%^&*)',
    uppercase: 'uppercase letter',
  },
  password_required: 'Password is required',
  required: 'Required',
};

export default translationFormFieldValidationError;
