/**
 * @file We use ids to identify form fields
 * The translations can be found in {@link file://./../../i18n/en/form-field.ts}
 */

/**
 * Ids of form fields we use to identify translations, tooltips, etc..
 */

export enum FieldId {
  BodyWeightPercentage = 'Field_BodyWeightPercentage',
  CoachingFormat = 'Field_CoachingFormat',
  Comments = 'Field_Comments',
  Complexity = 'Field_Complexity',
  ConfirmPassword = 'Field_ConfirmPassword',
  CycleName = 'Field_CycleName',
  Difficulty = 'Field_Difficulty',
  Email = 'Field_Email',
  ExecutionType = 'Field_ExecutionType',
  ExerciseSearch = 'Field_ExerciseSearch',
  ExerciseType = 'Field_ExerciseType',
  ExerciseViewSelect = 'Field_ExerciseViewSelect',
  ExternalUrl = 'Field_ExternalUrl',
  FirstName = 'Field_FirstName',
  Instruction = 'Field_Instruction',
  IsUnilateral = 'Field_IsUnilateral',
  LastName = 'Field_LastName',
  Login = 'Field_Login',
  Name = 'Field_Name',
  NewPassword = 'Field_NewPassword',
  OldPassword = 'Field_OldPassword',
  Password = 'Field_Password',
  PrimaryGoals = 'Field_PrimaryGoals',
  PrimaryMuscles = 'Field_PrimaryMuscles',
  RestDetails = 'Field_RestDetails',
  RestHours = 'Field_RestHours',
  SecondaryGoals = 'Field_SecondaryGoals',
  SecondaryMuscles = 'Field_SecondaryMuscles',
  SelectClient = 'Field_SelectClient',
  SelectExercise = 'Field_SelectExercise',
  SetComments = 'Field_SetComments',
  SetIsOptional = 'Field_SetSetIsOptional',
  SetRest = 'Field_SetRest',
  SetRpe = 'Field_SetRpe',
  SetSets = 'Field_SetSets',
  SetTempo = 'Field_SetTempo',
  SetVolume = 'Field_SetVolume',
  SetVolumeUnit = 'Field_SetVolumeUnit',
  SetWeight = 'Field_SetWeight',
  TemporaryPassword = 'Field_TemporaryPassword',
  Terms = 'Field_Terms',
  Thumbnail = 'Field_Thumbnail',
  Username = 'Field_Username',
  Variation = 'Field_Variation',
  Video = 'Field_Video',
  VolumeUnits = 'Field_VolumeUnits',
  WorkoutCompareColumnsFilter = 'Field_WorkoutCompareColumnsFilter',
  WorkoutCompareSelect = 'Field_WorkoutCompareSelect',
}
