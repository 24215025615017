import { px } from '@mantine/core';
import { memo, PropsWithChildren } from 'react';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { vars } from '../../theme';

interface Props {
  Component: React.FC;
}

export function MobileOnly({ Component }: Props) {
  const { width } = useWindowDimensions();
  const mobileBreakpoint = px(vars.breakpoints.sm) as number;

  if (width >= mobileBreakpoint) {
    return null;
  }

  return <Component />;
}

export function DesktopOnly({ Component }: Props) {
  const { width } = useWindowDimensions();
  const mobileBreakpoint = px(vars.breakpoints.sm) as number;

  if (width < mobileBreakpoint) {
    return null;
  }

  return <Component />;
}

function MemoizedComponent({ children }: PropsWithChildren) {
  return <>{children}</>;
}

export const Memoized = memo(MemoizedComponent);
